import * as React from "react"
import { navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Button from "./button"

/**
 * Primary card component
 */

const Card = ({
  h2,
  image,
  alt,
  children,
  buttonText,
  destination,
  textAlignment,
}) => {
  const align = textAlignment === "center" ? "text-center" : "text-left"
  return (
    <div className={["card", "space-y-5", "bg-white", align].join(" ")}>
      {image && <GatsbyImage image={image} alt={alt} />}
      <h2 className="font-serif text-2xl text-gray-900">{h2}</h2>
      {children}
      <Button label={buttonText} onClick={() => navigate(`${destination}`)} />
    </div>
  )
}

export default Card
