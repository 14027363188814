import * as React from "react"
import { navigate } from "gatsby"

import Button from "./button"

const PageCTA = ({ h2, children, buttonText, destination, mailto}) => (
  <section className="container my-4 mx-auto pt-0 sm:px-24 px-4 pb-6">
    <div className="flex flex-col">
      <div className="my-6">
        <hr/>
      </div>
      <div className="flex flex-col md:flex-row space-y-4">
        <div className="flex-grow space-y-4">
          <h2 className="font-serif text-4xl">{h2}</h2>
          {children}
        </div>
        <div className="flex-grow">
          <div className="flex justify-center">
            {destination &&
              <Button
                label={buttonText}
                onClick={() => navigate(`${destination}`)}
              />
            }
            {mailto &&
              <div className="bg-purple-500 hover:bg-white  border hover:border-purple-500 px-6 py-4 rounded-full uppercase">
                <a className="text-white hover:text-purple-500 visited:text-white" href={`mailto:${mailto}`}>{buttonText}</a>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default PageCTA